import 'maplibre-gl/dist/maplibre-gl.css';
import maplibregl from 'maplibre-gl';
import React, {
  useRef, useLayoutEffect, useEffect, useState,
} from 'react';
import { SwitcherControl } from '../switcher/switcher';
import { useAttributePreference, usePreference } from '../../common/util/preferences';
import usePersistedState, { savePersistedState } from '../../common/util/usePersistedState';
import { mapImages } from './preloadImages';
import useMapStyles from './useMapStyles';

const element = document.createElement('div');
element.style.width = '100%';
element.style.height = '100%';
element.style.boxSizing = 'initial';

export const map = new maplibregl.Map({
  container: element,
  attributionControl: false,
});

let ready = false;
const readyListeners = new Set();

const addReadyListener = (listener) => {
  readyListeners.add(listener);
  listener(ready);
};

const removeReadyListener = (listener) => {
  readyListeners.delete(listener);
};

const updateReadyValue = (value) => {
  ready = value;
  readyListeners.forEach((listener) => listener(value));
};

const initMap = async () => {
  if (ready) return;
  if (!map.hasImage('background')) {
    Object.entries(mapImages).forEach(([key, value]) => {
      map.addImage(key, value, {
        pixelRatio: window.devicePixelRatio,
      });
    });
  }
  updateReadyValue(true);
};

map.addControl(new maplibregl.NavigationControl());

const imageUrl = `${process.env.PUBLIC_URL}/SantaIsabelTresPoniente.png`;
fetch(imageUrl)
  .then((response) => response.blob())
  .then(() => {
    const image = new Image();
    image.src = imageUrl;
    image.onload = () => {
      map.addImage('myImage', image);
      map.addLayer({
        id: 'overlay',
        type: 'raster',
        source: {
          type: 'image',
          url: image.src,
          coordinates: [
            [-70.78054085, -33.50390889],
            [-70.77973586, -33.50387669],
            [-70.77964793, -33.50536453],
            [-70.78045451, -33.50539698],
          ],
        },
        paint: {
          'raster-opacity': 1,
        },
      });
    };
  });

const switcher = new SwitcherControl(
  () => updateReadyValue(false),
  (styleId) => savePersistedState('selectedMapStyle', styleId),
  () => {
    map.once('styledata', () => {
      const waiting = () => {
        if (!map.loaded()) {
          setTimeout(waiting, 33);
        } else {
          initMap();
        }
      };
      waiting();
    });
  },
);

map.addControl(switcher);

const MapView = ({ children }) => {
  const containerEl = useRef(null);

  const [mapReady, setMapReady] = useState(false);

  const mapStyles = useMapStyles();
  const activeMapStyles = useAttributePreference('activeMapStyles', 'locationIqStreets,osm,carto');
  const [defaultMapStyle] = usePersistedState('selectedMapStyle', usePreference('map', 'locationIqStreets'));
  const mapboxAccessToken = useAttributePreference('mapboxAccessToken');
  const maxZoom = useAttributePreference('web.maxZoom');

  useEffect(() => {
    if (maxZoom) {
      map.setMaxZoom(maxZoom);
    }
  }, [maxZoom]);

  useEffect(() => {
    maplibregl.accessToken = mapboxAccessToken;
  }, [mapboxAccessToken]);

  useEffect(() => {
    const imageUrl = `${process.env.PUBLIC_URL}/SantaIsabelTresPoniente.png`;
    fetch(imageUrl)
      .then((response) => response.blob())
      .then(() => {
        const image = new Image();
        image.src = imageUrl;
        image.onload = () => {
          map.addImage('myImage', image);
          map.addLayer({
            id: 'overlay',
            type: 'raster',
            source: {
              type: 'image',
              url: image.src,
              coordinates: [
                [-70.78054085, -33.50390889],
                [-70.77973586, -33.50387669],
                [-70.77964793, -33.50536453],
                [-70.78045451, -33.50539698],
              ],
            },
            paint: {
              'raster-opacity': 1,
            },
          });
        };
      });
  }, []);

  useEffect(() => {
    const imageUrl = `${process.env.PUBLIC_URL}/JumboConchaYToro.png`;
    fetch(imageUrl)
      .then((response) => response.blob())
      .then(() => {
        const image = new Image();
        image.src = imageUrl;
        image.onload = () => {
          map.addImage('myImage2', image);
          map.addLayer({
            id: 'overlay2',
            type: 'raster',
            source: {
              type: 'image',
              url: image.src,
              coordinates: [
                [-70.58654514, -33.57094665],
                [-70.58379426, -33.57058190],
                [-70.58342952, -33.57258203],
                [-70.58614430, -33.57295254],
              ],
            },
            paint: {
              'raster-opacity': 1,
            },
          });
        };
      });
  }, []);

  useEffect(() => {
    const imageUrl = `${process.env.PUBLIC_URL}/Lider_MallPlazaNorte.png`;
    fetch(imageUrl)
      .then((response) => response.blob())
      .then(() => {
        const image = new Image();
        image.src = imageUrl;
        image.onload = () => {
          map.addImage('myImage3', image);
          map.addLayer({
            id: 'overlay3',
            type: 'raster',
            source: {
              type: 'image',
              url: image.src,
              coordinates: [
                [-70.68301755, -33.36218027],
                [-70.67212062, -33.36360135],
                [-70.67328211, -33.37002303],
                [-70.68419492, -33.36863304],
              ],
            },
            paint: {
              'raster-opacity': 1,
            },
          });
        };
      });
  }, []);

  useEffect(() => {
    const imageUrl = `${process.env.PUBLIC_URL}/LiderLaReina.png`;
    fetch(imageUrl)
      .then((response) => response.blob())
      .then(() => {
        const image = new Image();
        image.src = imageUrl;
        image.onload = () => {
          map.addImage('myImage4', image);
          map.addLayer({
            id: 'overlay4',
            type: 'raster',
            source: {
              type: 'image',
              url: image.src,
              coordinates: [
                [-70.54677824, -33.45916160],
                [-70.54265476, -33.45951414],
                [-70.54290250, -33.46152051],
                [-70.54702345, -33.46120293],
              ],
            },
            paint: {
              'raster-opacity': 1,
            },
          });
        };
      });
  }, []);

  useEffect(() => {
    const filteredStyles = mapStyles.filter((s) => s.available && activeMapStyles.includes(s.id));
    const styles = filteredStyles.length ? filteredStyles : mapStyles.filter((s) => s.id === 'osm');
    switcher.updateStyles(styles, defaultMapStyle);
  }, [mapStyles, defaultMapStyle]);

  useEffect(() => {
    const listener = (ready) => setMapReady(ready);
    addReadyListener(listener);
    return () => {
      removeReadyListener(listener);
    };
  }, []);

  useLayoutEffect(() => {
    const currentEl = containerEl.current;
    currentEl.appendChild(element);
    map.resize();
    return () => {
      currentEl.removeChild(element);
    };
  }, [containerEl]);

  return (
    <div style={{ width: '100%', height: '100%' }} ref={containerEl}>
      {mapReady && children}
    </div>
  );
};

export default MapView;
